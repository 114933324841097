/* Define variables */
/* :root {
    --main-color: #336699;
    --accent-color: #ff6600;
    --font-family: Arial, sans-serif;
} */

.navbar {
    display: flex;
    justify-content:end;
    margin: 0 20px;
    /* flex-direction: row;
    align-items:center; */
}

.navitem {
    list-style-type: none;
    display: flex;
    justify-content: end;
    /* align-items:center; */
}

