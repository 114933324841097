:root {
  /* Dark mode colors */
  --background-color: #000;
  --text-color: #fff;
  --font-size-heading:26px;
  /* button dark mode and when hovered over*/
  --button-bg-color:#e2a04c;
  --button-text-color:#fff;
  --button-border-color:#000;
  /* ContactForm CSS */
  --form-font-size: 18px;
  --form-input-font-size: 16px;
  /* Heart Emoji Colour */
  --heart-color: #fff;
  /* Font familty used in components*/
  --font-family:'Roboto', sans-serif;
  --font-family--name--highlight: 'Segoe Script';
}

/* CSS for Full body background and text colour */

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

/* CSS for Contact Form Heading */

.form-header {
  color:var(--button-bg-color);
  text-align: center;
  font-size: var(--font-size-heading);
  /* Slightly bigger font size */
  margin-bottom: 20px;
}

/* ContactForm.css modifications for button only */
button {
  font-size: var(--form-font-size);
  display: block;
  margin: 20px auto;
  /* Adjusted for demonstration */
  padding: 10px 20px;
  background-color: var(--button-bg-color);
  /* Color of button */
  color: var(--button-text-color);
  /* Text color */
  border: 2px solid var(--button-border-color);
  /* Border color and thickness */
  border-radius: 22px;
  /* Makes the corners of the button rounded/semicircle */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  /* Smooth transition for hover effects */
}

button:hover {
  background-color:var(--button-border-color);
  /* Button color on hover */
  border-color: var(--button-bg-color);
  /* Border color on hover */
  color: var(--button-bg-color);
  /* Adjust text color on hover if needed */
  box-shadow: 0 0 8px 0 var(--button-bg-color);
  /* Shadow effect */
}

/* To switch to light mode */
body.light-mode-theme {
  /* Dark mode colors */
  --background-color: #fff;
  --text-color: #000;
  /* button light mode and when hovered over*/
  --button-bg-color: #c60ac4;
  --button-text-color: #fff;
  --button-border-color: #fff;
  /* Heart Emoji Colour */
  --heart-color: #ff0000;
}