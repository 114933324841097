.about-me-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--font-family);
    font-size: var(--form-font-size);
    padding: 30px;
    margin-left: 50px;
    /* Adjust left margin */
    margin-right: 50px;
    /* Adjust right margin */
    margin-top: 30px;
    /* Add space between About Me and Contact sections */
}

.about-me-content {
    flex: 1;
    max-width: calc(50% - 20px);
    /* Adjusted width */
    margin-right: 20px;
    /* Add space between content and photo */
}

/* .about-me-content h2 {
    margin-bottom: 10px;
    color:var(--button-bg-color);
} */

p{
    text-align: justify;
}

.photo-container {
    max-width: calc(50% - 20px);
    /* Adjusted width */
}

.profile-photo {
    border: 4px solid #e2a04c;
    /* Border color */
    border-radius: 50%;
    /* Make the photo circular */
    max-width: 250px;
    /* Limit photo width */
    max-height: 250px;
    /* Limit photo height */
    
}

@media (max-width: 768px) {
    .about-me-container {
        flex-direction: column;
        /* Stack content vertically on smaller screens */
    }

    .about-me-content,
    .photo-container {
        max-width: 100%;
        /* Take full width on smaller screens */
        margin: 0;
        /* Reset margins */
    }

    .photo-container {
        margin-top: 20px;
        /* Add margin between content and photo on smaller screens */
    }
}

/* About Me Content CSS */

.myname {
    font-size: var(--font-size-heading);
    font-family: var(--font-family--name--highlight);
    color: var(--button-bg-color);
    text-transform: capitalize;
}

/* .intro {
    font-size: 3em;
    text-transform: capitalize;
} */

.linkedin {
    font-family: 'Segoe Script';
    color: var(--button-bg-color);
}

.heart {
    font-size: 120%;
    color: var(--heart-color);
}
