/* ContactForm.css */
.contact-form {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    font-size: var(--form-font-size);
    font-family: 'Roboto', sans-serif;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Ensure equal spacing between fields */
    margin-bottom: 15px;
}

.half-width,
.full-width {
    flex-basis: calc(50% - 5px);
    /* Adjusted to accommodate space between fields */
}

.full-width {
    margin-bottom: 15px;
    flex-basis: 100%;
    /* Ensure full width for the full-width field */
}

label {
    font-size: var(--form-font-size);
    display: block;
    margin-bottom: 4px;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: var(--form-input-font-size);
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

input.input-error,
textarea.input-error {
    border-color: red;
}

.error {
    color: red;
    font-size: 14px;
}


/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {

    .half-width,
    .full-width {
        flex-basis: 100%;
        /* Each takes full width on smaller screens */
        margin-bottom: 10px;
        /* Add margin to separate fields vertically */
    }
}